import React from 'react';

function PrOpen(props) {
    return (
        <svg
            className={props.className}
            stroke="currentColor"
            viewBox="0 0 16 16"
            version="1.1"
            aria-hidden="true"
        >
            <path
                fillRule="evenodd"
                d="M2.5 3.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.25 1a2.25 2.25 0 00-.75 4.372v5.256a2.251 2.251 0 101.5 0V5.372A2.25 2.25 0 003.25 1zm0 11a.75.75 0 100 1.5.75.75 0 000-1.5zm9.5 3a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0-3a.75.75 0 100 1.5.75.75 0 000-1.5z"
            ></path>
            <path d="M14 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm0-4.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"></path>
        </svg>
    );
}

export default PrOpen;
